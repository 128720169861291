import { client } from "./client";

export const login = (formData: any) => {
  return client
    .post(`/auth/login`, formData)
    .then(({ data }: any) => {
      localStorage.setItem("token", data.data.token);
      client.defaults.headers.authorization = `Bearer ${data.data.token}`;
      return data;
    });
};
export const setToken= (token: string) => {
    client.defaults.headers.authorization = `Bearer ${token}`;
  };
export const me = () => {
  return client.get(`/user/me`);
};

export const createCategory = (data: any) => {
  return client.post(`/category`, data);
};

export const categoryList = (params: any = {}) => {
  return client.get(`/category`, { params });
};

export const getCategory = (id: string) => {
  return client.get(`/category/${id}`);
};

export const updateCategory = (id: any, body: any) => {
  return client.patch(`/category/${id}`, body);
};
export const createProduct = (data: any) => {
  return client.post(`/product`, data);
};

export const productList = (params: any = {}) => {
  return client.get(`/product`, { params });
};

export const getProduct = (id: string) => {
  return client.get(`/product/${id}`);
};

export const updateProduct = (id: any, body: any) => {
  return client.patch(`/product/${id}`, body);
};
export const changePassword = (body: any) => {
  return client.patch(`/user/change-password`, body);
};

import axios from 'axios';
import { handleToast } from '../pages/components/Toast';

const instance = axios.create({
  baseURL: 'https://api.thinkerplace.co/api/v1',
  // baseURL: 'http://localhost:5555/api/v1',
});

instance.interceptors.response.use(
  (successRes: any) => successRes,
  ({ response }: any) => {
    if (response?.status === 401) {
      localStorage.removeItem('token');
      window.location.reload();
    } else if (response.status >= 500) {
      let messageString = '';
      response.data.errorDetail.forEach((element: string) => {
        messageString += element + '\n';
      });
      handleToast(messageString);
    } else if (response.status >= 400) {
      handleToast(response.data.message.msg);
    }

    return Promise.reject(response);
  }
);

export const client = instance;

import * as routePaths from "../../RouteConstant";
import { Category, Product } from "../../pages/components/Icon";

export const MenuArr = [
  {
    id: "0",
    heading: "Menu",
    data: [
      {
        id: "0.1",
        icon: Category,
        text: "Category",
        active: true,
        link: routePaths.CATEGORY_LIST,
      },
      {
        id: "0.2",
        icon: Product,
        text: "Product",
        active: true,
        link: routePaths.PRODUCT_LIST,
      },
    ],
  },
];

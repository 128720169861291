import { ReactNode, useContext, useState } from "react";
import { Header } from "../Header";
import { Sidebar } from "../Sidebar";
import {
  MenuArr,
} from "../Sidebar/constant";
import { AppContext } from "../../App";
import { ComponentWrapper } from "../../pages/components/ComponentWrapper";

interface IComponentWrapper {
  children: ReactNode;
}

function modulePresent(arr1: any, arr2: any) {
  for (const element of arr1) {
    if (arr2.has(element)) {
      return true;
    }
  }

  return false;
}

const filterMenuByPermission = (user: any) => {
  if (user.isSuperAdmin) {
    return MenuArr;
  }

  const { modelPermissions } = user;

  if (!modelPermissions) {
    return [];
  }

  const permittedModules = new Set(Object.keys(modelPermissions));

  // filter main menu by permission
  let filteredMenu = MenuArr.map((mainMenu: any) => {
    mainMenu.data = mainMenu.data.filter((menuItem: any) => {
      return menuItem?.modules
        ? modulePresent(menuItem?.modules, permittedModules)
        : true;
    });
    return mainMenu;
  });

  //filter submenu by permission
  filteredMenu = filteredMenu.map((mainMenu: any) => {
    mainMenu.data = mainMenu.data.map((menuItem: any) => {
      if (menuItem?.child) {
        //filter child
        menuItem.child = menuItem.child.filter((child: any) =>
          child.module ? permittedModules.has(child.module) : true
        );
      }
      return menuItem;
    });
    return mainMenu;
  });

  return filteredMenu;
};

export const Layout: React.FC<IComponentWrapper> = ({ children }) => {
  const [open, setOpen] = useState(true);
  const { state } = useContext<any>(AppContext);
  let menu = filterMenuByPermission(state.user);
  return (
    <ComponentWrapper
      sidebar={
        <Sidebar
          toggle={open}
          setToggle={setOpen}
          menuArr={menu}
        />
      }
      header={
        <Header
          open={open}
          setOpen={setOpen}
        />
      }
    >
      {children}
    </ComponentWrapper>
  );
};

import * as routePaths from "./RouteConstant";
import { Navigate, RouteObject } from "react-router-dom";
import React, { Suspense, useContext } from "react";
import { AppContext } from "./App";
import { Layout } from "./shared/Layout";

const PageNotFound = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.PageNotFound,
  }))
);

const AdminDashboard = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.AdminDashboard,
  }))
);
const Login = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.Login,
  }))
);

const ResetEmailPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.ResetEmailPage,
  }))
);
const ChangePasswordPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.ChangePasswordPage,
  }))
);
const CategoryListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.CategoryList,
  }))
);
const CategoryAddPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.AddEditCategory,
  }))
);
const ProductListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.ProductList,
  }))
);
const ProductAddPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.AddEditProduct,
  }))
);


const AuthRoutes = ({ component }: any) => {
  const { state } = useContext<any>(AppContext);
  const isLoggedIn = !!state?.user;
  const isLoading = state?.app_loading;
  if (!isLoggedIn) {
    return <Navigate to={routePaths.LOGIN} />;
  }
  return (
    <>
      {!isLoading ? (
        <Layout>
          <Suspense fallback={"Loading"}>{component}</Suspense>
        </Layout>
      ) : null}
    </>
  );
};

const PublicRoutes = ({ component }: any) => {
  return <Suspense fallback={"Loading"}>{component}</Suspense>;
};
export const routes: RouteObject[] = [
  {
    path: "/",
    children: [
      {
        path: routePaths.DASHBOARD,
        element: <AuthRoutes component={<AdminDashboard />} />,
      },
      {
        path: routePaths.LOGIN,
        element: <PublicRoutes component={<Login />} />,
      },
      {
        path: routePaths.FORGOT_PASSWORD,
        element: <PublicRoutes component={<ResetEmailPage />} />,
      },
      {
        path: routePaths.FORGOT_PASSWORD,
        element: <PublicRoutes component={<ResetEmailPage />} />,
      },
      {
        path: routePaths.RESET_PASSWORD,
        element: <AuthRoutes component={<ChangePasswordPage />} />,
      },
      {
        path: routePaths.CATEGORY_LIST,
        element: <AuthRoutes component={<CategoryListPage />} />,
      },
      {
        path: routePaths.CATEGORY_ADD,
        element: <AuthRoutes component={<CategoryAddPage />} />,
      },
      {
        path: routePaths.CATEGORY_EDIT,
        element: <AuthRoutes component={<CategoryAddPage />} />,
      },
      {
        path: routePaths.PRODUCT_LIST,
        element: <AuthRoutes component={<ProductListPage />} />,
      },
      {
        path: routePaths.PRODUCT_ADD,
        element: <AuthRoutes component={<ProductAddPage />} />,
      },
      {
        path: routePaths.PRODUCT_EDIT,
        element: <AuthRoutes component={<ProductAddPage />} />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <Suspense fallback={"Loading"}>
        <PageNotFound />
      </Suspense>
    ),
  },
];

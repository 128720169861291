import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { useState, type FC } from "react";
import { Down, NavIcon } from "../../pages/components/Icon";
import { Logout } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { RESET_PASSWORD } from "../../RouteConstant";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/logo.png";

interface IHeader {
  setOpen: Function;
  open: boolean;
}

export function stringAvatar(name: string) {
  return {
    children: `${name.split(" ")[0][0].toLocaleUpperCase()}${
      name.split(" ")[1] ? name.split(" ")[1][0].toLocaleUpperCase() : ""
    }`,
  };
}

export const Header: FC<IHeader> = ({ setOpen, open }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };
  const classes = {
    root: {
      position: "sticky",
      top: 0,
      zIndex: 99,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "4.5rem",
      boxShadow: "0px 2px 10px 0px rgba(27, 46, 94, 0.04)",
      background: "#FFF",
      "& img": {
        height: "38px",
        background: "#000000",
      },
    },
  };
  return (
    <Box pl={open ? 0 : 3} pr={3} sx={classes.root}>
      <Box height={1} display="flex" alignItems="center">
        {open ? (
          <IconButton
            sx={{
              borderRadius: 0,
              width: "auto",
              px: "1.5rem",
              height: "100%",
            }}
            onClick={() => setOpen((prev: boolean) => !prev)}
          >
            <NavIcon />
          </IconButton>
        ) : (
          <img src={Logo} alt="logo" />
        )}
      </Box>

      <Box display="flex" gap={2.5}>
        <Box borderLeft="1px solid #E1E3E6" pl={2.5}>
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              aria-expanded={menuOpen ? "true" : undefined}
              aria-controls={menuOpen ? "account-menu" : undefined}
              sx={{
                width: "auto",
                height: "auto",
                padding: 0,
                gap: 1,
                backgroundColor: "transparent",

                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Down />
            </IconButton>
          </Tooltip>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={menuOpen}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={() => navigate(RESET_PASSWORD)}>
              <ListItemIcon>
                <AccountCircleIcon fontSize="small" />
              </ListItemIcon>
              Change password
            </MenuItem>
            <MenuItem onClick={logout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};
